export const EVENTS = {
  CLOSE_CHAT_WINDOW: "close_chat_window",
  AGENT_JOINED: "agentJoined",
  AGENT_LEFT: "agentLeft",
  CONVERSATION_STARTED: "conversationStarted",
  USER_INTERACTED: "userInteracted",
};

export const ACTIONS = {
  INCOMMING_ACTIVITY: "DIRECT_LINE/INCOMING_ACTIVITY",
};
